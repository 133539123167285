import React from "react"
import PropTypes from "prop-types"
// Components
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from "../components/layout"
import styled from "styled-components"

const PostList = styled.li`
    display: inline-block;
  margin: 0;
  font-size: 17px;
  background-color: #ff457d;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  font-family: Montserrat;
  border-style: solid;
  border-color: white;
  box-shadow: 5px 10px 18px #888888;
  min-width: auto;
  padding-left: 9px;
  padding-right: 9px;
  text-align: center;
`

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} article${
    totalCount === 1 ? "" : "s"
  } tagged with: "${tag}"`
  return (
    <Layout location={tagHeader} title={tagHeader}>
      <SEO title={tagHeader} description={`Posts tagged "${tag}"`}/>
      <div>
        On this page you will find all articles related to
         <strong> {tag}</strong>.
        Click on the tag to continue to the article:
      </div>
      <br></br>
      <ul>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title } = node.frontmatter
          return (
            <PostList key={slug}>
              <a 
                href={slug}
                style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `white`,
                }}
                >
                  {title}
              </a>
            </PostList>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              We'll come back to it!
            */}
      <a href="/tags">All tags</a>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}
export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            published
          }
        }
      }
    }
  }
`